import React from "react"
import {BsLightningCharge, BsLightbulb} from 'react-icons/bs';


export default function LowWeightIcon(){

    return (
        <div className="custom-icon">
            <div className="custom-icon-wrapper">
                <BsLightningCharge className="react-icon"/>
            </div>
        </div>
    )
}