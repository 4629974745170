import React, { useEffect } from "react";
import marketingBeach from '../walletImages/marketingBeach.png'
import { useNavigate } from "react-router-dom";
import FiveStarsDisplay from "./FiveStarsDisplay";
import { getDesignsWallets } from "./firebaseConfig";
import Footer from "./Footer";


export default function Home(props) {
    const navigate = useNavigate();


    let mobileDisplayMessage = <div className="mobile-message-wrapper">
        <p className="mobile-home-text">GET SOMETHING SPECIAL THIS FATHER'S DAY</p>
        <p>A SIMPLE "THANK YOU"</p>
    </div>

    function handleClick(){
        navigate('/shop')
    }


    async function getWallets(){
        let wallets = await getDesignsWallets()
        return
    }

    useEffect(()=>{
        getWallets()
    }, [])

    
    return (
        <div className="home-container">
             <div className="home-menu-wrapper">
                 {props.Menu}
             </div>

             <div className="content-container">
                 <div className="content-container-left">
                     <h1 style={{textTransform:'uppercase'}}>Summer Deals<br/> Are Now Available.</h1>
                     <button onClick={()=> navigate('/shop')}>Shop Now</button>
                 </div>

                 <div className="content-container-right">
                     <div className="overlay"/>
                     <div className="home-main-image-wrapper">
                         <img src={marketingBeach} className='image'/>
                     </div>
                 </div>     
             </div>
             
             <Footer />
        </div>

    )
}
