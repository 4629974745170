import React, { useEffect,  useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Checkout from "./Checkout";
import ConfirmedOrder from "./ConfirmedOrder";
import Home from "./Home";
import Menu, {OpenMenu} from "./Menu";
import OpenedProduct from "./OpenedProduct";
import Shop from "./Shop";
import AboutUs from "./AboutUs"
import AdminLogin from "./AdminLogin";
import AdminHome from "./AdminHome";
import Blogs from './Blogs'
import OpenBlogPost from "./OpenBlogPost";
import ContactUsPage from "./ContactUsPage"
// import {OpenedShoppingCart} from '../components/ShoppingCart'




export default function Router(props){
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [openMenuRef, setOpenMenuRef] = useState(null)
    const [pathname, setPathname] = useState(null)
    const [isShoppingCartOpen, setIsShoppingCartOpen] = useState(null)
    const removeItemFromCart = props.removeItemFromCart;
    const menu = <Menu setPathname={setPathname} addItemToBag={props.addItemsToCart} itemsInCart={props.itemsInCart} removeItemFromCart={removeItemFromCart} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>;

    useEffect(()=>{
        if(openMenuRef){
            return listenClick()
        }
    })

    useEffect(()=>{console.log(pathname)}, [pathname])

    useEffect(()=> {setIsMenuOpen(false)}, [pathname])
    const listenClick = () => {
        if(openMenuRef){
            document.body.addEventListener('click', (e)=>{
                if(e.target.classList[0] === 'background-cover'){
                    setIsMenuOpen(false)
                    setOpenMenuRef(false);
                }
            })
        }
    }


    return (
        <div>
            <BrowserRouter>
                {isMenuOpen && <OpenMenu setOpenMenuRef={setOpenMenuRef} setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen}/>}
                {isMenuOpen && <div className="background-cover"></div>}
                <Routes>
                    <Route path="/" element={<Home Menu={menu} />}/>
                    <Route path="/shop" element={<Shop Menu={menu} />} />
                    <Route path="/about" element={ <AboutUs Menu={menu}/> }/>
                    <Route path="/shop/:productID" element={<OpenedProduct Menu={menu} addItemToBag={props.addItemsToCart}/>} />
                    <Route path='/checkout' element={<Checkout itemsInCart={props.itemsInCart} />} />
                    <Route path='/admin/home' element={<AdminHome Menu={menu}/>} />
                    <Route path="/confirmation/:payment_intent_client_secret/:confirmation_number" element={<ConfirmedOrder Menu={menu} /> } />
                    <Route path='/admin' element={<AdminLogin Menu={menu}/>} />
                    <Route path='/blog' element={<Blogs Menu={menu}/>} />
                    <Route path='/blog/:title/:category' element={<OpenBlogPost Menu={menu}/>} />
                    <Route path='/contact' element={<ContactUsPage Menu={menu}/>}/>
                </Routes>                
            </BrowserRouter>
        </div>

    )
}

