import React from "react";
import {HiOutlineArrowTrendingUp} from 'react-icons/hi2';


export default function TrendingDesignIcon(){

    return (
        <div className="custom-icon">
            <div className="custom-icon-wrapper">
                <HiOutlineArrowTrendingUp className="react-icon"/>
            </div>
        </div>
    )
}