import React, { useEffect, useState } from "react";
import { Logo } from "./Checkout";
import { useNavigate } from "react-router-dom";

export default function AdminLogin(props){
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [token, setToken] = useState(null);
    const [errMessage, setErrMessage] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        if(token){return navigate('/admin/home', {state: {token: token}})}
    })

    useEffect(() =>{
        if(errMessage){
            setErrMessage(null)
        }
    }, [username, password])

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('clickedd')
        const formData = {username, password, action:'login'}
        const payload = JSON.stringify(formData)
        console.log(payload)
        const data = await fetch('https://dreiserver.com/admin', {body: payload, method:"POST", headers:{'Content-Type': 'application/json'}});

        let result = await data.json()
        if(result.err){
            console.log(result.err)
            return setErrMessage(result.err)
        }
        setToken(result.token)
        console.log(result)
    }
    
    return (
        <div className="login-page-container">
                {props.Menu}
                {/* <Logo/>
                <h1 style={{fontSize:'1.4rem'}}>Admin Portal</h1>    */}
            <form onSubmit={handleSubmit} className="admin-form">
                <h1>Admin Portal</h1>
                {errMessage && <p className="error-message">{errMessage}</p>}
                <div className="input-wrapper">
                    <input className="login-input" onChange={(e) => setUsername(e.target.value)} name="username" placeholder="Username"/>
                </div>
                <div className="input-wrapper">
                    <input type="password" onChange={(e)=> setPassword(e.target.value)} className="login-input" name="password" placeholder="Password"/>
                </div>
                <button className="admin-page-login-button">Login</button>
            </form>
        </div>
    )
}