import React from 'react';
import { NavContainer } from './Menu';


export default function Footer() {
    
    return (
        <div className='footer-container'>
            <div className='footer-section'>
                <h2 style={{textAlign:'center'}}>PAGES</h2>
                <NavContainer className='footer-nav'/>
            </div>
            
            <div className='footer-section bottom-right' style={{}}>
                <h2>JOIN OUR NEWSLETTER</h2>
                <div style={{gap: '1rem', display:'grid'}}>
                    <p  style={{fontWeight:'600', textAlign:'center'}}>Stay connected with us! Subscribe to our newsletter for the latest updates, exclusive offers, and more. Enter your email and keep an eye out for our emails in your inbox</p>
                    <form>
                        <input placeholder='Email'/>
                        <button style={{padding: '0 .7rem',backgroundImage:' linear-gradient(171deg, var(--main-blue), #dd9957)', border:'none',outline:'none' ,color:'white', borderRadius:'.25rem', fontWeight: 700}}>Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    )
}