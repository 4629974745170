import React from "react"
import {RiCustomerService2Fill} from 'react-icons/ri';


export default function customerServiceIcon(){

    return (
        <div className="custom-icon">
            <div className="custom-icon-wrapper">
                <RiCustomerService2Fill className="react-icon"/>
            </div>
        </div>
    )
}