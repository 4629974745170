import React from "react"
import {AiOutlineShrink} from 'react-icons/ai';


export default function SaveSpaceIcon(){

    return (
        <div className="custom-icon">
            <div className="custom-icon-wrapper">
                <AiOutlineShrink className="react-icon"/>
            </div>
        </div>
    )
}