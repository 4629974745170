import React from "react"
import {BsWifiOff} from 'react-icons/bs';



export default function RfidProtectionIcon(){

    return (
        <div className="custom-icon">
            <div className="custom-icon-wrapper">
                <BsWifiOff className="react-icon"/>
            </div>
        </div>
    )
}