import React, { useState } from "react";
import {AiOutlineHeart, AiFillHeart} from 'react-icons/ai';
import {TbMessageCircle2} from 'react-icons/tb';
import {CgArrowLongRight} from 'react-icons/cg';


export default function BlogPostButtons(){
    const [postLiked, setPostLiked] = useState(false)

    return(
        <div className="blog-buttons-wrapper">
            <div style={{display:'flex', gap:'1.5rem'}}>
                {!postLiked && <AiOutlineHeart onClick={()=> setPostLiked(true)}/>}
                {postLiked && <AiFillHeart opacity={.5} color="var(--main-blue)"/>}
                <TbMessageCircle2 />
            </div>
            <div>
                <CgArrowLongRight className={'read-now-icon'}/>
            </div>

        </div>
    )
}