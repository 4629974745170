import React from "react"
import { FaRegGem} from 'react-icons/fa';


export default function HighQualityIcon(){

    return (
        <div className="custom-icon">
            <div className="custom-icon-wrapper">
                <FaRegGem className="react-icon"/>
            </div>
        </div>
    )
}