import React, { useEffect, useRef, useState } from "react";
import {IoIosMenu, IoMdClose} from 'react-icons/io'
import { Link } from "react-router-dom";
import { Logo } from "./Checkout";
import ShoppingCart from "./ShoppingCart";
import { useLocation } from "react-router-dom";

export default function Menu(props) {
    const [systeMessage, setSystemMessage] = useState("LIFETIME WARRANTY & 75 DAY RISK-FREE TRIAL");
    const setIsMenuOpen = props.setIsMenuOpen;
    const isMenuOpen = props.isMenuOpen;
    const pathname = useLocation().pathname;

    useEffect(()=>{
        props.setPathname(pathname)
    })
 

    function handleMenuClick(){
        return setIsMenuOpen(!isMenuOpen)
    }

    const handleAddItem = props.addItemToBag;

    return (
        <div className="menu-container">
            <div className="menu-content-wrapper">
                <div className="menu-buttons-container">
                <div> <IoIosMenu className="menu-icon menu-text" isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} onClick={()=>{setIsMenuOpen(!isMenuOpen)}} style={{color: "white", fontSize: '2.3rem'}}/> </div>
                <Link to={'/shop'}><button className="menu-text" style={{background: 'none' ,border: 'none', fontWeight: 600, fontSize: '.9rem'}}>Wallets</button></Link>         
                </div>

                <div className="logo-wrapper">
                  <div className="logo menu-text">
                    <p>DREI</p>
                  </div>            
                </div>


                <div className="shopping-cart-wrapper">
                    {<ShoppingCart className='menu-icon' addItemToBag={handleAddItem} itemsInBag={props.itemsInCart} removeItemFromCart={props.removeItemFromCart}/>}
                </div>
            </div>


            <div className="main-system-message-container">
                <p>{systeMessage}</p>
            </div>
        </div>
    )
}

export function OpenMenu(props){
    const setOpenMenuRef = props.setOpenMenuRef;
    const openMenuRef = useRef(null);

    useEffect(() => {
        if(openMenuRef) {setOpenMenuRef(openMenuRef.current)}
    })

    const closeMenu = () => props.setIsMenuOpen(false);


    return (
        <div ref={openMenuRef} className="open-menu-container">
            <div style={{display:'flex'}}>
                <IoMdClose className="close-menu-icon" onClick={() => props.setIsMenuOpen(!props.isMenuOpen) }/>
                <Logo/>
            </div>
            <NavContainer classToAdd='nav-wrapper' closeMenu={closeMenu}/>
            {/* <h1 className="menu-title">Menu</h1> */}
            
            <Link to={'/admin'}><button onClick={closeMenu} className="admin-login-button">Admin Portal</button></Link>
        </div>
    )
}

export const NavContainer = ({closeMenu, classToAdd}) => {

    return (
        <nav className={classToAdd}>
            <ul className="nav-item-list">
                <Link to={'/'}><li onClick={closeMenu || null} className="menu-item">Home</li></Link>
                <Link to={'/shop'}><li onClick={closeMenu || null} className="menu-item">Shop</li></Link>
                <Link to={'/about'}><li onClick={closeMenu || null} className="menu-item">About us</li></Link>
                <Link to={'/blog'}><li onClick={closeMenu || null} className="menu-item">Blog</li></Link>
                <Link to={'/contact'}><li onClick={closeMenu || null} className="menu-item">Contact Us</li></Link>
            </ul>
        </nav>
    )
}