import React, { useEffect, useState } from "react";
import { json, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "./Checkout";
import Loader from './Loader'
import {TfiPackage} from 'react-icons/tfi';
import {RxEnvelopeOpen} from 'react-icons/rx'
import {IoMdClose} from 'react-icons/io'

export default function AdminHome(props) {
    const [jwt, setJwt] = useState(null)
    const [admin, setAdmin] = useState(null);
    const [err, setErr] = useState(null);
    const state = useLocation().state;
    const [orders, setOrders] = useState(null)
    const [openOrder, setOpenOrder] = useState(null)
    const navigate = useNavigate();
    const [clickedOrderData, setClickedOrderData] = useState(null)

    useEffect(() => {
        if (!state) {
            navigate('/admin')
        }
        else {
            setJwt(`Bearer ${state.token}`)
        }
    }, [])

    useEffect(() => {
        if (jwt && !admin) {
            getAdmin()
        }

        if (jwt && admin && !orders) {
            getOrders()
        }
    })

    async function getOrders() {
        const orders = await fetch('https://dreiserver.com/admin-orders', { headers: { 'Authorization': jwt } })
        const list = await orders.json()
        setOrders(list)

    }

    async function getAdmin() {
        const result = await fetch('https://dreiserver.com/admin', { headers: { 'Authorization': `${jwt}` }, method: 'GET' })

        try {
            const data = await result.json()
            setAdmin(data)
        }
        catch (err) {
            console.log(err)
        }
    };

    function handleClickedOrder(order) {
        setOpenOrder(!openOrder)
        setClickedOrderData(order)
    }


    if (jwt && orders) {
        return (
            <div className="admin-home-container">
                {props.Menu}
                <div className="admin-main-content">
                    <div className="admin-orders-header" >
                        <div className="admin-left">
                            <h1 className="admin-title">{new Date().toDateString()}</h1>
                            <h2 className="admin-sub-header" >{orders.length} Orders</h2>
                            <h2 className="admin-title" style={{position:'absolute', bottom:0, left:'1rem'}}>Pending Orders</h2>
                        </div>
                        <div className="admin-right">
                            <div style={{position: 'relative'}}>
                                <TfiPackage fontSize={'4rem'}/>
                                <p style={{position:'absolute', right: '40%', top: '20%', fontWeight: 800, fontSize:'1.3rem'}}>{orders.length}</p>
                            </div>
                            <div style={{position: 'relative'}}>
                                <RxEnvelopeOpen fontSize={'4rem'}/>
                                <p style={{position:'absolute', right: '40%', top: '20%', fontWeight: 800, fontSize:'1.3rem'}}>{0}</p>
                            </div>
                        </div>
                    </div>

                    <div className="orders-preview">
                        {orders.map((order, i) => {
                            return (
                                <div key={i} onClick={()=> handleClickedOrder(order)} className="order-container">
                                    <p >{new Date(order.receivedAt).toDateString()}</p>
                                    <p>{`Shipping Status:${order.orderShipped ? true : "Awaiting "}`}</p>
                                    <p>{`Aliexpress Status: ${order.orderPlacedOnAliEx ? true : 'Order not placed'}`}</p>
                                    <p>{`Confirmation #: ${order._id}`}</p>
                                    <p>Tracking #: {orders.trackingNumber || 'N/A'}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {openOrder && <OpenOrder setOpenOrder={setOpenOrder} order={clickedOrderData}/>}
                {/* Will never render, evaluating against false******** */}
                {/* {false && <NewAdminForm/>} */}
            </div>
        )
    }
    else {
        return <Loader />
    }

}

function OpenOrder({order, setOpenOrder}) {
    const {checkoutSession} = order;
    const {user} = checkoutSession;
    const {receivedAt, orderShipped, trackingNumber, items, orderPlacedOnAliEx, confirmEmailSent} = order;
    const {status, totalAmount, taxAmount, paymentIntentId} = checkoutSession;
    const {email, firstName, lastName, phoneNumber, shippingAddress} = user;

    const userInfo = {firstName, lastName, email, phoneNumber, shippingAddress};
    const orderInfo = {receivedAt, items, orderShipped, orderPlacedOnAliEx, trackingNumber, confirmEmailSent}
    const checkoutInfo = {status, totalAmount, taxAmount, paymentIntentId}

    
    return (
        <div className="open-order-container">
            <div className="open-order">
                <BuyerInfo setOpenOrder={setOpenOrder} userInfo={userInfo}/>
                <CheckoutInfo checkoutSession={checkoutInfo}/>
                <OrderInfo orderInfo={orderInfo} />
            </div>
        </div>
    )
}


const OrderField = (fieldName, value, classToAdd) => {
    return (
        <div className="field-container">
            <p className={`order-field-name`} >{fieldName}</p>
            <p className={`order-field-value ${classToAdd}`} >{value}</p>
        </div>
    )
}

const BuyerInfo = ({userInfo, setOpenOrder}) => {
    const {firstName, lastName, email, phoneNumber, shippingAddress} = userInfo;
    const {line1, line2, city, state, postal_code, country } = shippingAddress.address;
    const fieldNames = ['First Name', 'Last Name', 'Email', 'Phone Number']
    const values = [firstName, lastName, email, phoneNumber]
    

    return (
        <div>
            <div onClick={()=> setOpenOrder(null)} className="close-order-btn" style={{position: 'absolute', right: '2rem', top: '2rem', border: '.14rem solid var(--title-gray', borderRadius:'900rem', width: '2rem', height:'2rem', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <IoMdClose fontSize={'1.4rem'}/>
            </div>

            {/* <h1>BUYER INFORMATION</h1> */}
            {fieldNames.map((field, i)=> {
                return OrderField(field, values[i])
            })}
            {OrderField('For', shippingAddress.name)}
            {OrderField("Ship To", `${line1}, ${line2} ${city+ " " +state}, ${postal_code}, ${country}`)}
        </div>
    )
}

const CheckoutInfo = ({checkoutSession}) => {
    const {status, totalAmount, taxAmount, paymentIntentId} = checkoutSession;
    const fieldNames = ['Payment Status', 'Tax', 'Gross', 'Stripe PaymentIntentID'];
    const values = [status, (`$${(taxAmount / 100).toFixed(2)}`), (`$${(totalAmount / 100).toFixed(2)}`) ,paymentIntentId];

    return (
        <div>
            {/* <h1>BUYER INFORMATION</h1> */}
            {fieldNames.map((field, i)=> {
                let classToAdd
                if(field === 'Payment Status'){classToAdd = 'text-green'}
                return OrderField(field, values[i], classToAdd)
            })}
            {OrderField("Net", `$${((taxAmount + totalAmount) / 100).toFixed(2)}`)}
        </div>
    )
}

const OrderInfo = ({orderInfo}) => {
    const {receivedAt, items, orderShipped, orderPlacedOnAliEx, trackingNumber, confirmEmailSent} = orderInfo;
    const fieldNames = ['Order Time', 'Order Shipped', 'Ordered On AliExpress', 'Tracking #', 'Sent Confirmation Email'];
    const values = [
        (new Date(receivedAt).toDateString()),
        (orderShipped ? 'True' : 'False'),
        (orderPlacedOnAliEx ? 'True' : 'False'),
        (trackingNumber ? trackingNumber : "N/A"),
        (confirmEmailSent ? 'True' : 'False')
    ]

        return (
            <div>
                {/* <h1>BUYER INFORMATION</h1> */}
                {fieldNames.map((field, i)=> {
                    return OrderField(field, values[i])
                })}
                {/* {OrderField("Net", ((taxAmount + totalAmount) / 100).toFixed(2))} */}
            </div>
        )
}



// {formContainer && <div className="admin-form-container">
// <h1>New</h1>
// <NewBlogForm />
// </div>}
// {newAdminForm && <div className="new-admin-form-container"><NewAdminForm jwt={jwt} setNewAdminForm={setNewAdminForm} /></div>}

function NewBlogForm() {
    return (
        <form method="POST" action="https://dreiserver.com/blog" className="blog-form" style={{ display: 'grid', gap: '.5rem' }} encType="multipart/form-data">

            <div className="form-left">
                <div className="blog-input-container">
                    <input name="author" placeholder="Author" />
                    <input name="category" placeholder="Category" />
                </div>

                <input name="blogTitle" placeholder="Title" />
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', }}>
                    <div className="image-select-container">
                        <input name="featuredImage" type="file" />
                    </div>
                    <div>
                        <input name="publishOn" type="date" />
                    </div>
                </div>
                <textarea name="content" placeholder="Blog content..." />
            </div>

            <div className="form-right">
                <textarea name="tags" placeholder="Tags(Relevant keywords to be used for SEO)" />
                <textarea name="blogSummary" placeholder="Summary" />
            </div>

            <button>Post Blog</button>
        </form>
    )
}



function NewAdminForm(props) {
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null)
    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)
    const [role, setRole] = useState(null)


    async function handleSubmit(e) {
        e.preventDefault();
        console.log('clicked')
        const formData = { firstName, lastName, username, password, role, action: 'new' }
        const payload = JSON.stringify(formData);
        console.log(payload)

        const data = await fetch('https://dreiserver.com/admin', { headers: { 'Content-Type': "application/json", 'Authorization': props.jwt }, body: payload, method: 'POST' });

        const result = await data.json();

        if (result.err) {
            console.log(result.err)
            props.setNewAdminForm(false)
        }
        else {
            console.log(result)
            props.setNewAdminForm(false)
        }
    }

    return (
        <div className="admin-form-wrapper">
            <form onSubmit={handleSubmit} className="new-admin-form">
                <h1>New Admin</h1>
                <input onChange={(e) => setFirstName(e.target.value)} placeholder="Name" name='firstName' />
                <input onChange={(e) => setLastName(e.target.value)} placeholder="Last name" name='lastName' />
                <input onChange={(e) => setUsername(e.target.value)} placeholder="Username" name='username' />
                <input onChange={(e) => setPassword(e.target.value)} placeholder="Password" name='password' />
                <input onChange={(e) => setRole(e.target.value)} placeholder="Role" name='role' />
                <button>Add Admin</button>
            </form>
        </div>
    )
}
function AdminBlogSection(props){
    return (
      <div className='admin-blog-section admin-section'>
         <h2>Blogs</h2>
            <div className="blog-actions-container">
                <div>
                    <button>New Blog Post</button>
                </div>
                <div>
                    <button>New Author</button>
                 </div>
                <div>
                    <button>New Blog Category</button>
                </div>
                <div>
                    <button onClick={() => { props.setNewAdminForm(!props.newAdminForm) }}>New Admin</button>
                </div>
            </div>
        </div>  
    )
}

